import React, { useContext, useMemo, useRef } from "react";
import type { BoxProps } from "@chakra-ui/react";
import {
  Box,
  Text,
  Circle,
  PopoverTrigger,
  Popover,
  PopoverBody,
  PopoverContent,
  Stack,
  Portal,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  PopoverHeader,
  Center,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ProjectHeaderImage } from "../../project/projectLandingTileLayouts/components/ProjectHeaderImage";
import { MarkdownViewer } from "screens/markdown/MarkdownViewer";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { useProjectParams } from "hooks";
import { useNavigate } from "react-router-dom";
import { HiOutlineExternalLink } from "react-icons/hi";
import { ConversationContext } from "screens/thread/ConversationContext";

export interface QuadrantData {
  x: number;
  y: number;
  projectId?: string;
  explanations?: {
    [key: string]: string | undefined;
  };
}

interface QuadrantChartProps extends BoxProps {
  data: QuadrantData[];
  xAxisLabel: string;
  yAxisLabel: string;
}

const QuadrantChart: React.FC<QuadrantChartProps> = ({ data, xAxisLabel, yAxisLabel, ...rest }) => {
  const { hoveredProjectId, setHoveredProjectId } = useContext(ConversationContext);
  const { projectFilter, parentRoute } = useProjectParams();
  const navigate = useNavigate();

  const quadrantColors = useMemo(
    () => ({
      volatile: "rgba(254, 215, 165, 0.5)",
      stable: "rgba(198, 246, 213, 0.5)",
      marginal: "rgba(254, 215, 215, 0.5)",
      potential: "rgba(235, 248, 255, 0.5)",
    }),
    []
  );
  const quadrantLabels = useMemo(
    () => [
      { text: "Volatile", top: "5%", left: "25%", transform: "translate(-50%, -50%)" },
      { text: "Stable", top: "5%", right: "25%", transform: "translate(50%, -50%)" },
      { text: "Marginal", bottom: "5%", left: "25%", transform: "translate(-50%, 50%)" },
      { text: "Potential", bottom: "5%", right: "25%", transform: "translate(50%, 50%)" },
    ],
    []
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(containerRef);
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });

  const normalizeCoordinate = (value: number, max = 100) => (value / max) * 100;

  const groupedPoints = useMemo(() => {
    const groups: { [key: string]: { data: QuadrantData; index: number }[] } = {};
    data.forEach((point, index) => {
      const key = `${point.x}-${point.y}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push({ data: point, index });
    });
    return groups;
  }, [data]);

  const getPointOffset = (x: number, y: number, index: number): { offsetX: number; offsetY: number; groupCount: number } => {
    const key = `${x}-${y}`;
    const group = groupedPoints[key];
    if (!group || group.length <= 1) return { offsetX: 0, offsetY: 0, groupCount: 1 };

    const position = group.findIndex((item) => item.index === index);
    const totalPoints = group.length;
    const offsetY = position * -10;

    return { offsetX: 0, offsetY, groupCount: totalPoints };
  };

  return (
    <Box className={"ch-project-landing-metric-quadrant"} position="relative" height="19rem" width={isMobile ? "4/3" : "31rem"} {...rest}>
      <Box
        position="absolute"
        top={0}
        left={3}
        right={0}
        bottom={4}
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gridTemplateRows="repeat(2, 1fr)">
        {Object.values(quadrantColors).map((color, index) => (
          <Box key={index} bg={color}>
            <Text position="absolute" fontSize={{ base: "sm", md: "xs" }} color="gray.600" {...quadrantLabels[index]}>
              {quadrantLabels[index].text}
            </Text>
          </Box>
        ))}
      </Box>
      {/* Diagonal dotted line */}
      <Box position="absolute" top={0} left={3} right={0} bottom={4} zIndex={1} pointerEvents="none" overflow="hidden">
        <Box
          position="absolute"
          width="141.4%" // sqrt(2) * 100% to account for rotation
          height="0"
          borderTop="2px dotted"
          borderColor="orange.200"
          top="50%"
          left="50%"
          transform={`translate(-50%, -50%) rotate(${isMobile ? "-39deg" : "-30.5deg"})`}
        />
      </Box>
      {data.map((point, index) => {
        const { offsetX, offsetY } = getPointOffset(point.x, point.y, index);
        return (
          <Popover key={index} trigger="hover" isLazy>
            <PopoverTrigger>
              {point.projectId && (
                <Stack
                  spacing="0"
                  position="absolute"
                  left={`${normalizeCoordinate(point.x)}%`}
                  bottom={`${normalizeCoordinate(point.y)}%`}>
                  <Circle
                    zIndex={hoveredProjectId && hoveredProjectId === point.projectId ? 4 : 3}
                    size={{ base: "8px", md: hoveredProjectId === point.projectId ? "12px" : "8px" }}
                    bg={hoveredProjectId === point.projectId ? "gray.500" : "gray.300"}
                    transform="translate(-50%, 50%)"
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  />
                  <Box
                    zIndex={hoveredProjectId && hoveredProjectId === point.projectId ? 5 : 3}
                    _hover={{ zIndex: 4 }}
                    boxShadow={hoveredProjectId === point.projectId ? "lg" : "md"}
                    opacity={hoveredProjectId && hoveredProjectId !== point.projectId ? 0.3 : 1}
                    borderColor={hoveredProjectId === point.projectId ? "gray.500" : "gray.300"}
                    borderWidth="1px"
                    backgroundColor={"white"}
                    py="3px"
                    px="5px"
                    borderRadius={"sm"}
                    width="100%"
                    transform={`translate(${offsetX}px, ${offsetY}px)`}
                    transition="transform 0.2s ease-in-out"
                    onMouseEnter={() => setHoveredProjectId(point.projectId)}
                    onMouseLeave={() => setHoveredProjectId(undefined)}>
                    <ProjectHeaderImage maxHeight=".7rem" width="4rem" collectionId={point.projectId} logoOnly />
                  </Box>
                </Stack>
              )}
            </PopoverTrigger>
            <Portal>
              <PopoverContent width="100%" boxShadow={"lg"} maxWidth={"50rem"}>
                <PopoverHeader bgColor="white" fontSize="md" borderTopRadius={"md"}>
                  <Stack direction="row" spacing={0} width="100%" justifyContent="space-between">
                    <Box pl="1rem" pt=".3rem">
                      <ProjectHeaderImage maxHeight="1.5rem" width="8rem" collectionId={point.projectId} logoOnly />
                    </Box>
                    <Center>
                      <SmallActionButton
                        iconTypeName={HiOutlineExternalLink}
                        onClick={() => {
                          navigate(`/${parentRoute}/${projectFilter}/${point.projectId}`);
                        }}
                        tooltip={"Open project"}
                      />
                    </Center>
                  </Stack>
                </PopoverHeader>
                <PopoverBody width="100%" ref={containerRef} backgroundColor="gray.700">
                  <Stack>
                    {point.explanations && (
                      <Tabs size="sm">
                        <TabList color="white">
                          {Object.keys(point.explanations ?? {}).map(
                            (key, idx) =>
                              point.explanations?.[key] && (
                                <Tab _selected={{ color: "white", fontWeight: "semibold", fontsize: "md" }} key={idx}>
                                  {key.toUpperCase()}
                                </Tab>
                              )
                          )}
                        </TabList>
                        <TabPanels maxHeight={"20rem"} overflow={"auto"} css={scrollbarStyle}>
                          {Object.entries(point.explanations).map(
                            ([key, explanation], idx) =>
                              explanation && (
                                <TabPanel key={idx}>
                                  <MarkdownViewer fontColor="white" backgroundColor="gray.700" content={explanation} fontSize="sm" />
                                  <Box
                                    position="absolute"
                                    bottom="0"
                                    left="0"
                                    right="0"
                                    height="30px"
                                    background="linear-gradient(to bottom, rgba(45,55,72,34%), rgba(45,55,72,1))"
                                    pointerEvents="none"
                                  />
                                </TabPanel>
                              )
                          )}
                        </TabPanels>
                      </Tabs>
                    )}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        );
      })}
      <Box position="absolute" bottom={"-6px"} left={"25px"} right={0} display="flex" justifyContent="left">
        <Stack direction={"row"} spacing=".5rem" height="100%">
          <Text fontSize="xs" color="charli.primaryBlue" whiteSpace="nowrap">
            {xAxisLabel}
          </Text>
          <Center>
            <TriangleDownIcon color="charli.primaryBlue" transform="rotate(-90deg)" boxSize={"0.8rem"} />
          </Center>
        </Stack>
      </Box>
      <Box position="absolute" bottom={"-25px"} left="49px" height="100%" display="flex">
        <Stack direction={"row"} spacing=".5rem" height="100%" transform="rotate(-90deg)">
          <Text fontSize="xs" color="charli.primaryBlue" whiteSpace="nowrap">
            {yAxisLabel}
          </Text>
          <TriangleDownIcon color="charli.primaryBlue" transform="rotate(-90deg)" boxSize={"0.8rem"} mt="3px" />
        </Stack>
      </Box>
    </Box>
  );
};

export default QuadrantChart;
