import React, { useContext, useEffect, useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { ToastMessageContent } from "screens/common/components";
import { ConversationContext } from "screens/thread/ConversationContext";
import type { RootState } from "state/rootReducer";
import { sendMessage } from "state/websocket/operations";
import type { DynamicEntities, DynamicEntitiesPayload, RequestEntities } from "types/charliui";
import { useAddToCharliContext } from "./AddToCharliProvider";
import { v4 as uuid } from "uuid";
import {
  useCollection,
  useCommaSeparatedEntitiesMap,
  useConfigMap,
  useFeatureFlags,
  useProjectParams,
  useUserPreference,
  useUserPreferences,
} from "hooks";
import sortBy from "lodash/sortBy";

export const useSendToCharli = () => {
  const { projectId = "", contentId } = useProjectParams();
  const configMap = useConfigMap();
  const { uppyFiles, resetFormState, isNewMenuAction } = useAddToCharliContext();
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const dispatch = useDispatch();
  const toast = useToast();
  const { onConversationOpen, setConversationId, conversationId, requestEntities, setRequestEntities } = useContext(ConversationContext);
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const commaSeparatedEntitiesMap = useCommaSeparatedEntitiesMap(configMap);
  const [currentConversationId, setCurrentConversationId] = useState<string | undefined>(undefined);
  const collection = useCollection(projectId);
  const { test_new_ux: showNewUX } = useFeatureFlags();

  const entities = useMemo(() => {
    if (requestEntities && requestEntities.length > 0) {
      const updatedEntities: RequestEntities = [];
      const filterEntitiesContainingDashes = requestEntities.filter((entity) => !entity.entity.includes("--"));

      filterEntitiesContainingDashes.forEach((requestEntity) => {
        const splitEntity = commaSeparatedEntitiesMap[requestEntity.entity];
        if (splitEntity) {
          requestEntity.value.split(splitEntity).forEach((itemValue) => {
            if (itemValue.length > 0) {
              updatedEntities.push({ entity: requestEntity.entity, value: itemValue.trim() });
            }
          });
        } else {
          updatedEntities.push({ entity: requestEntity.entity, value: requestEntity.value });
        }
      });
      return updatedEntities;
    }
    return [];
  }, [requestEntities, commaSeparatedEntitiesMap]);

  const jsonEntities = useMemo(() => {
    if (!requestEntities || requestEntities.length === 0) {
      return undefined;
    }
    const updatedEntities: DynamicEntities[] = requestEntities
      .filter((entity) => entity.entity.includes("--"))
      .reduce((result, entity) => {
        const [entityLabel, parentEntityLabel, key] = entity.entity.split("--");
        const parentEntityKey = `${parentEntityLabel}_${key}`;

        result[parentEntityLabel] = result[parentEntityLabel] || {};
        result[parentEntityLabel][parentEntityKey] = result[parentEntityLabel][parentEntityKey] || {};
        result[parentEntityLabel][parentEntityKey][entityLabel] = result[parentEntityLabel][parentEntityKey][entityLabel] || [];

        if (entity.value !== "") {
          const splitEntityValueArray = entity.value.split(",").map((value) => value.trim());
          result[parentEntityLabel][parentEntityKey][entityLabel] = splitEntityValueArray;
        }

        return result;
      }, {} as DynamicEntities[]);

    if (Object.keys(updatedEntities).length === 0) {
      return undefined;
    }

    const finalEntities = sortBy(Object.entries(updatedEntities), ([key]) => key).reduce((acc, [key, value]) => {
      acc[key] = Object.values(value);

      return acc;
    }, {} as DynamicEntities[]);

    const charliUIArray: DynamicEntitiesPayload[] = [];
    charliUIArray.push({
      type: "json_entities",
      body: {
        dynamic_entities: finalEntities,
      },
      includeInResponse: true,
    });

    return charliUIArray;
  }, [requestEntities]);

  const sendRequest = useCallback(
    async (intent: string, onClose?: () => void, toastMessage?: string, onClick?: () => void) => {
      if (!isConnected || !intent) {
        return;
      }

      const getConversationId = (() => {
        const newConversationId = isNewMenuAction ? uuid() : contentId || collection?.conversationId || conversationId || uuid();
        setConversationId(newConversationId);
        setCurrentConversationId(newConversationId);
        return newConversationId;
      })();

      dispatch(
        sendMessage({
          conversationId: getConversationId,
          intent: `/${intent}`,
          entities: entities,
          files: uppyFiles,
          datum: jsonEntities,
        })
      );

      if (toastMessage) {
        toast.closeAll();
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={toastMessage}
              onClick={() => {
                currentConversationId && setConversationId(currentConversationId);
                if (onClick) {
                  onClick();
                } else {
                  !showNewUX && onConversationOpen();
                }
                onClose();
              }}
              onClose={onClose}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      onClose && onClose();
      setCurrentConversationId(undefined);
      setRequestEntities([]);

      const currentRequestEntities = entities.reduce((acc, { entity, value }) => {
        acc[entity] = value;
        return acc;
      }, {});
      injectDebugEntityPreference && console.log({ isConnected: isConnected, intent: intent, entities: currentRequestEntities });
    },
    [
      collection?.conversationId,
      contentId,
      conversationId,
      currentConversationId,
      dispatch,
      entities,
      injectDebugEntityPreference,
      isConnected,
      isNewMenuAction,
      jsonEntities,
      onConversationOpen,
      setConversationId,
      setRequestEntities,
      showNewUX,
      toast,
      uppyFiles,
    ]
  );

  const useCharliRequestLog = (isPanelOpen: boolean) => {
    const { uppyFiles } = useAddToCharliContext();
    const { userPreferences } = useUserPreferences();
    const currentRequestEntities = entities.reduce((acc, { entity, value }) => {
      acc[entity] = value;
      return acc;
    }, {});
    const logConversationId = conversationId || contentId || collection?.conversationId || uuid();

    useEffect(() => {
      if (injectDebugEntityPreference && isPanelOpen) {
        console.log({
          requestEntities: currentRequestEntities || undefined,
          jsonEntities: (jsonEntities && jsonEntities[0].body.dynamic_entities) || undefined,
          "current conversationId": logConversationId,
          "Attached Files": uppyFiles.map(({ name }) => name) || undefined,
        });
      }
    }, [logConversationId, currentRequestEntities, isPanelOpen, uppyFiles, userPreferences]);
  };

  return { sendRequest, resetFormState, useCharliRequestLog, entities, jsonEntities };
};
