import type { SuggestedQuestions } from "api/suggestions";
import { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { Source } from "types/config";
import { getTickersQuery } from "api/tickers";
import orderBy from "lodash/orderBy";
import { useEntitlementKey } from "./useEntitlements";

export const qaSourcesMap: Record<string, { label: string; value: Source }> = {
  tickers: {
    label: "Tickers list",
    value: "tickers",
  },
  questions: {
    label: "Questions list",
    value: "questions",
  },
};

export const useQASourceListFunction = (source: Source | undefined): ((params: unknown) => Promise<SuggestedQuestions>) => {
  const questionsData = useSelector((state: RootState) => state.suggestedQuestions.questionData, shallowEqual);
  const showEtfTickers = useEntitlementKey("ui_show_etf_tickers");

  const getTickers = useCallback(
    async (params: unknown) => {
      const { limit, query } = params as { limit?: number; query?: string };

      const data = await getTickersQuery({ limit, query, ...(!showEtfTickers && { excludedType: ["etf"] }) });
      const sortedData = orderBy(data, [(tickerData) => tickerData.symbol.toLowerCase() === query?.toLocaleLowerCase()], ["desc"]);

      return sortedData.map((tickerData) => ({
        question: `${tickerData.name} | (${tickerData.symbol})`,
        matchFilter: [tickerData.symbol.toLowerCase(), tickerData.name.toLowerCase()],
        focus: tickerData.exchange,
        type: tickerData.type,
      }));
    },
    [showEtfTickers]
  );

  const getQuestions = useCallback(
    async (params: unknown) => {
      return questionsData;
    },
    [questionsData]
  );

  return useMemo(() => {
    if (source === "tickers") {
      return getTickers;
    } else if (source === "questions") {
      return getQuestions;
    } else {
      return () => Promise.resolve([]);
    }
  }, [getQuestions, getTickers, source]);
};
