import { Stack, Text, useColorModeValue, Divider, Box, useOutsideClick, useBreakpointValue, Center, Image } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useRef, useState, startTransition, useEffect } from "react";
import React, { useContext, useMemo } from "react";
import ECHighlighter from "react-ec-highlighter";
import { ContentFilterContext } from "screens/content";
import type { Question, Answer } from "types/question";
import { AnswerStatus } from "types/question";
import { useCollectionKey, useLatestCollectionWorkflowId, useTileProps } from "hooks";
import { AnswerCardPinned } from "./AnswerCardPinned";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";
import { Popover } from "react-tiny-popover";
import { ProjectQuestionHotlinks } from "screens/collection/views/ProjectActions/ProjectQuestionHotlinks";
import { focusBadgeColor } from "./AutocompleteInput";
import HelpPopover from "screens/landing/components/popoverComponent/HelpPopover";
import { ConversationContext } from "screens/thread/ConversationContext";
import DataAnalysisGif from "screens/common/static/images/data-analysis.gif";
import { useWorkflowKey } from "hooks/useWorkflows";

interface Props {
  questionAnswers: Question[];
  collectionId?: string;
}

export const QuestionAnswerResults: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  questionAnswers,
  collectionId,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const bgColorQA = useColorModeValue("white", "gray.800");
  const { searchText, setSearchText } = useContext(ContentFilterContext);
  const conversationId = useCollectionKey(collectionId, "conversationId") || "";
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>(questionAnswers ?? []);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { submittedQuestion, setSubmittedQuestion } = useContext(ConversationContext);
  const commonTileProps = useTileProps();
  const latestWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const workflowStatus = useWorkflowKey(latestWorkflowId, "status");

  const DataAnalysisMessage = () => (
    <Center>
      <Stack direction="row" spacing="1rem" justifyContent={"space-between"} {...commonTileProps} borderRadius={"none"}>
        <Center>
          <Image src={DataAnalysisGif} alt="AI Results Empty" width={isMobile ? "4rem" : "6rem"} />
        </Center>
        <Center width="100%">
          <Stack width="100%">
            <Text textAlign={"left"} fontSize="sm">
              {`I am collecting the data now and will generate a response for ${submittedQuestion} shortly.`}
            </Text>
            <Text textAlign={"left"} fontSize="sm">
              {`Once complete you can ask another question to dive even deeper.`}
            </Text>
          </Stack>
        </Center>
      </Stack>
    </Center>
  );

  useEffect(() => {
    const hasFilteredQuestionsContainsSubmittedQuestion = filteredQuestions.some((question) => question.question === submittedQuestion);
    hasFilteredQuestionsContainsSubmittedQuestion && setSubmittedQuestion(undefined);
  }, [filteredQuestions, submittedQuestion, setSubmittedQuestion]);

  const getQuestionAnswers = useMemo(() => {
    const questionsMap = filteredQuestions.reduce(
      (acc: Record<string, { pinnedAnswers: Answer[]; unpinnedAnswers: Answer[] }>, question) => ({
        ...acc,
        [question.id]: {
          pinnedAnswers: question.answers.filter((answer) => answer.answerStatus === "pinned"),
          unpinnedAnswers: question.answers.filter((answer) => answer.answerStatus === "unpinned"),
        },
      }),
      {}
    );

    return (questionId: string, answerStatus: AnswerStatus | "all") => {
      const answers = questionsMap[questionId];

      if (!answers) {
        return [];
      }

      return answerStatus === "pinned"
        ? answers.pinnedAnswers
        : answerStatus === "unpinned"
        ? answers.unpinnedAnswers
        : [...answers.pinnedAnswers, ...answers.unpinnedAnswers];
    };
  }, [filteredQuestions]);

  const [activeQuestionId, setActiveQuestionId] = useState<string | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const popRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: popRef!,
    handler: () => {
      setIsPopoverOpen(false);
      setActiveQuestionId(null);
    },
  });

  const renderSentimentBadge = (focus: string) => {
    return (
      <Box
        className={`ch-qa-result-question-focus-${focus ? focus.toLowerCase() : "none"}`}
        borderRadius={"4px"}
        height="1.2rem"
        lineHeight={"1.2rem"}
        px="5px"
        fontSize={"10px"}
        color="gray.600"
        bgColor={focusBadgeColor[focus.toUpperCase()]}>
        {focus.toUpperCase()}
      </Box>
    );
  };

  useEffect(() => {
    const questionsWithAnswers = questionAnswers || [];

    if (searchText && searchText.length > 0) {
      startTransition(() => {
        setFilteredQuestions(
          questionsWithAnswers.filter(
            (question) =>
              question.question.toLowerCase().includes(searchText.toLowerCase()) ||
              question.answers.filter((answer) => answer.answer.toLowerCase().includes(searchText.toLowerCase())).length > 0
          )
        );
      });
    } else {
      setFilteredQuestions(questionsWithAnswers);
    }
  }, [questionAnswers, searchText]);

  useEffect(() => {
    setSearchText("");
  }, [questionAnswers, setSearchText]);

  return (
    <Stack>
      <BlockSectionHeader title="AI Questions & Answers" />
      {submittedQuestion && workflowStatus === "in_progress" && (
        <Box mb="2rem" mt="1rem">
          <DataAnalysisMessage />
        </Box>
      )}
      {filteredQuestions.length > 0 &&
        filteredQuestions.map((questionRecord, questionIndex) => (
          <Stack width="100%" key={questionRecord.id} backgroundColor={bgColorQA} pb=".5rem">
            <Stack direction="row" justifyContent="space-between">
              <Stack
                ref={mainRef}
                position="relative"
                onTouchStart={() => {
                  setIsPopoverOpen(false);
                  setActiveQuestionId(null);
                }}
                onMouseEnter={() => {
                  setActiveQuestionId(questionRecord.id);
                }}>
                <Popover
                  isOpen={isPopoverOpen && activeQuestionId === questionRecord.id}
                  positions={["top"]}
                  padding={10}
                  align="start"
                  transformMode="relative"
                  onClickOutside={() => {
                    setIsPopoverOpen(false);
                    setActiveQuestionId(null);
                  }}
                  content={() => <ProjectQuestionHotlinks question={questionRecord.question} focus={questionRecord.focus} />}>
                  <Text
                    borderWidth={"1px"}
                    borderColor={isMobile ? "primary.default" : "transparent"}
                    borderStyle="dashed"
                    borderRadius={"full"}
                    px=".5rem"
                    _hover={{
                      borderColor: "primary.default",
                    }}
                    cursor={"pointer"}
                    onClick={() => {
                      setIsPopoverOpen(!isPopoverOpen);
                      setActiveQuestionId(questionRecord.id);
                    }}
                    width="100%"
                    className="ch-qa-result-question"
                    fontWeight="semibold"
                    color={textColor}
                    fontSize="md">
                    {searchText && searchText.length > 0 && questionRecord.question ? (
                      <ECHighlighter searchPhrase={searchText} text={questionRecord.question} />
                    ) : (
                      questionRecord.question
                    )}
                  </Text>
                </Popover>
              </Stack>
              {questionRecord.focus ? (
                <>
                  {questionIndex === 0 ? (
                    <HelpPopover
                      title="Sentiment Badge"
                      message="Click here to view the attributions on the source material Charli used for generating the response.">
                      {renderSentimentBadge(questionRecord.focus)}
                    </HelpPopover>
                  ) : (
                    renderSentimentBadge(questionRecord.focus)
                  )}
                </>
              ) : null}
            </Stack>
            <Stack justifyContent="space-between" spacing="1rem" mt="0!important" width="100%">
              {getQuestionAnswers(questionRecord.id, "all").length === 0 ? (
                <AnswerCardPinned questionRecord={questionRecord} collectionId={collectionId || ""} conversationId={conversationId} />
              ) : (
                getQuestionAnswers(questionRecord.id, AnswerStatus.pinned).map((answerRecord) => (
                  <AnswerCardPinned
                    questionRecord={questionRecord}
                    answerRecord={answerRecord}
                    enableHelpPopover={questionIndex === 0}
                    key={answerRecord.id}
                    collectionId={collectionId || ""}
                    conversationId={conversationId}
                  />
                ))
              )}
            </Stack>
            <Divider />
          </Stack>
        ))}
    </Stack>
  );
};
