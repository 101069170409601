import React, { useContext, useEffect, useMemo } from "react";
import { Stack, useBreakpointValue, Box, Center, Tag, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useItemSelector, useProjectParams, useTileProps } from "hooks";
import { getMetatDataForContent } from "screens/content/common/utils";
import type { ContentDetails } from "types/content/ContentDetails";
import { ContentPreview } from "screens/content/contentView/previewSection/ContentPreview";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { ConversationContext } from "screens/thread/ConversationContext";
import { hasProjectOutput } from "screens/collection/components/utils";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ProjectReportPanelNewReportModal } from "./ProjectReportPanelNewReportModal";
import { ProjectReportPanelTableContent } from "./ProjectReportPanelTableContent";

interface Props {
  contentData: ContentDetails[];
}

export const ProjectReportPanelLatestReport = ({ contentData }: Props) => {
  const { projectId } = useProjectParams();
  const { isProjectReportSelectorOpen, onProjectReportSelectorClose } = useAddToCharliContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const commonTileProps = useTileProps();
  const { setSelectedItems, setFocusedItem } = useItemSelector();
  const { setRequestEntities } = useContext(ConversationContext);

  const contentDataFiltered = useMemo(() => {
    const filtered = contentData?.filter((content) => {
      return hasProjectOutput(content);
    });
    const sorted = filtered?.sort((a: ContentDetails, b: ContentDetails) => {
      return new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime();
    });
    return sorted ? sorted.slice(0) : [];
  }, [contentData]);

  useEffect(() => {
    const fetchContentDetails = async () => {
      if (contentDataFiltered) {
        const metadata = getMetatDataForContent(contentDataFiltered[0]);
        if (metadata && metadata.mediaId.length > 0) {
          setFocusedItem({ id: metadata.mediaId, type: metadata.fileType, ...(metadata.extension && { extension: metadata.extension }) });
        }
        if (metadata && metadata.mediaId.length > 0) setSelectedItems({ [metadata.mediaId]: { type: metadata.fileType } });
        updateBatchRequestEntities([{ entity: metadata.fileType, value: metadata.mediaId, source: "selection" }], setRequestEntities);
      }
    };

    contentDataFiltered.length > 0 && fetchContentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {contentDataFiltered[0] && (
        <Stack
          direction={isMobile ? "column" : "row"}
          width="100%"
          justifyContent={"space-between"}
          pt="1rem"
          spacing={isMobile ? "1rem" : "2rem"}>
          <Stack
            position={"relative"}
            className="ch-latest-project-report"
            {...commonTileProps}
            borderRadius={"0"}
            spacing="0"
            p="0"
            height={"100%"}
            maxWidth={"15rem"}
            cursor="default">
            <Box position={"absolute"} zIndex={2} top="5px" right="5px">
              <Tag size={"sm"} variant="solid" colorScheme="green">
                LATEST
              </Tag>
            </Box>
            <Center>
              <ContentPreview
                backgroundColor={"white"}
                itemContent={contentDataFiltered[0]}
                thumbnailHeight={"300"}
                thumbnailWidth={"230"}
              />
            </Center>
          </Stack>
          <Box width="100%">
            <Table variant="simple" fontSize={isMobile ? "xs" : "sm"}>
              <Thead>
                <Tr>
                  <Th p={isMobile ? "0" : ".5rem"}>Name</Th>
                  {!isMobile && (
                    <Th textAlign={"center"} p={isMobile ? "0" : ".5rem"}>
                      Type
                    </Th>
                  )}
                  {!isMobile && (
                    <Th p={isMobile ? "0" : ".5rem"} width="10rem">
                      Date Added
                    </Th>
                  )}
                  <Th textAlign={"center"} p={isMobile ? "0" : ".5rem"}>
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <ProjectReportPanelTableContent contentData={contentData} />
              </Tbody>
            </Table>
          </Box>
        </Stack>
      )}
      {projectId && (
        <ProjectReportPanelNewReportModal
          onClose={onProjectReportSelectorClose}
          isOpen={isProjectReportSelectorOpen}
          collectionId={projectId}
        />
      )}
    </>
  );
};
