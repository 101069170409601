import { Text, useColorModeValue, Stack, Menu, MenuItem, MenuButton, MenuList, Icon, useBreakpointValue, Button } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { BiInfoSquare } from "react-icons/bi";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useUserPreference } from "hooks";

export const HelpMenu: React.FC = () => {
  const buttonColor = useColorModeValue("charli.darkBlue", "primaryButton.colorDarkMode");
  const subtitleColor = useColorModeValue("primary.lightGray", "primaryButton.colorDarkMode");
  const { showHelpPopups, setShowHelpPopups, isOnboardingModalOpen } = useConversationContext();
  const hasClosedOnboardingHelp = useUserPreference("ui_onboarding_help_closed") as boolean;
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  useEffect(() => {
    if (!hasClosedOnboardingHelp && !isOnboardingModalOpen) {
      setShowHelpPopups(true);
    } else {
      setShowHelpPopups(false);
    }
  }, [hasClosedOnboardingHelp, isOnboardingModalOpen, setShowHelpPopups]);

  const openWindow = useCallback((url: string) => () => window.open(url, "_blank"), []);

  const handleShowHintsClick = useCallback(() => {
    setTimeout(() => {
      setShowHelpPopups(!showHelpPopups);
    }, 300);
  }, [setShowHelpPopups, showHelpPopups]);

  const MenuItems = useMemo(
    () => [
      {
        text: `${showHelpPopups ? "Hide" : "Show"} Hints`,
        onClick: handleShowHintsClick,
        description: "Overlay hints on the screen to help you navigate and understand Charli.",
        color: buttonColor,
        disabled: false,
      },
      {
        text: "Submit Support Ticket",
        onClick: openWindow("https://support.charli.ai/hc/en-us/requests/new"),
        description: "Open a new support request.",
        color: buttonColor,
        disabled: false,
      },
      {
        text: "Explore help center",
        onClick: openWindow("https://support.charli.ai/"),
        description: "Explore our knowledge base and learn about Charli.",
        color: buttonColor,
        disabled: false,
      },
      {
        text: "Share an Idea",
        onClick: openWindow("https://portal.productboard.com/charli/1-product-portal/tabs/1-under-consideration/submit-idea"),
        description: "Share your feedback or create a feature request.",
        color: buttonColor,
        disabled: false,
      },
    ],
    [buttonColor, showHelpPopups, handleShowHintsClick, openWindow]
  );

  return (
    <Menu isLazy>
      <MenuButton
        className="ch-sidebar-nav-help"
        paddingInline={0}
        aria-label="Options"
        height={"1rem"}
        width={"6rem"}
        cursor={"pointer"}
        color="charli.darkBlue"
        _hover={{ color: "primary.default" }}
        _active={{}}
        variant="ghost"
        as={Button}
        textAlign={"left"}
        fontSize={isMobile ? "lg" : "sm"}
        fontWeight={"normal"}
        leftIcon={<Icon as={BiInfoSquare} boxSize={"1.2rem"} mr=".4rem" />}>
        Help
      </MenuButton>
      <MenuList minWidth={"unset"} zIndex={10}>
        {MenuItems.map(({ text, onClick, description, color, disabled }, index) => (
          <MenuItem key={index} style={{ padding: "1rem" }} onClick={onClick} disabled={disabled} _hover={{ color: "primary.default" }}>
            <Stack direction="row" fontSize="sm" maxWidth="16rem">
              <Stack spacing=".3rem">
                <Text color={color} _hover={{ color: "primary.default" }}>
                  {text}
                </Text>
                <Text fontSize="sm" color={subtitleColor}>
                  {description}
                </Text>
              </Stack>
            </Stack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
