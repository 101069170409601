import React, { useCallback, useContext, useMemo, useRef } from "react";
import type { SelectedQuestionAndFocus } from "screens/thread/ConversationContext";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCollectionKey, useConversation, useEntitlementKey, useFeatureFlags, useProjectParams } from "hooks";
import { useDispatch } from "react-redux";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { v4 as uuid } from "uuid";
import onSubmitDueDiligence from "screens/collection/components/useSubmitDueDiligence";
import { ToastMessageContent } from "screens/common/components";
import { useBreakpointValue, useToast } from "@chakra-ui/react";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useNavigate } from "react-router-dom";
import { useConversationDialogUtils } from "./useConversationDialogUtils";
import type { TickerType } from "api/tickers/models/TickerType";

interface Props {
  questionText?: string;
  selectedFocus?: string;
  afterSubmit?: () => void;
  conversationId?: string;
  mode?: "cli" | "suggested";
}

const createToastContent = (message: string, onClose: () => void, onClick?: () => void) => (
  <ToastMessageContent message={message} onClick={onClick} onClose={onClose} />
);

export const useConversationDialogSubmitFunctions = ({
  questionText,
  selectedFocus,
  afterSubmit,
  conversationId: conversationIdProp,
  mode = "cli",
}: Props) => {
  const { projectId, projectFilter, isPortfolios } = useProjectParams();
  const {
    onConversationOpen,
    isConversationOpen,
    onConversationClose,
    onSubscriptionModalOpen,
    conversationId,
    isOnboardingModalOpen,
    setConversationId,
    setSubmittedQuestion,
    onAIUpdatedModalOpen,
    isAIUpdatedModalOpen,
    setSelectedQuestionAndFocus,
  } = useConversationContext();
  const projectConversationId = useCollectionKey(projectId, "conversationId") || uuid();
  const currentConversationID = useMemo(
    () => conversationIdProp || projectConversationId || conversationId,
    [conversationIdProp, projectConversationId, conversationId]
  );
  const conversation = useConversation(currentConversationID);
  const { files, resetFiles } = useContext(InputBarDropzoneContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { show_modal_update_banner: showModalUpdateBanner, test_new_ux: showNewUX } = useFeatureFlags();
  const isEmbeddingModelVersionOutdated = useCollectionKey(projectId, "isEmbeddingModelVersionOutdated");
  const dueDiligenceConversationId = uuid();

  const portfolioId = useMemo(() => {
    if (projectFilter && isPortfolios) return projectFilter;
    return undefined;
  }, [projectFilter, isPortfolios]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const projectQuestionUsage = useFeatureUsage(Intent.generateAnswer);
  const { hasExceededMaxProjects, maxAllowedProjects } = useConversationDialogUtils();
  const canBypassUsageLimits = useEntitlementKey("bypass_usage_limits");

  const projectIdRef = useRef(projectId);
  projectIdRef.current = projectId;

  const handleSubmitted = useCallback(
    (message: string, openConversationId?: string, action?: () => void) => {
      toast.closeAll();
      toast({
        render: ({ onClose }) =>
          createToastContent(message, onClose, () => {
            if (openConversationId) {
              console.log(openConversationId);

              setConversationId(openConversationId);
              onConversationOpen();
            }
            onClose();
          }),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      action && action();
    },
    [onConversationOpen, setConversationId, toast]
  );

  const onSubmitDefaultRequest = useCallback(
    (questionText: string) => {
      if (!selectedFocus || !currentConversationID) return;
      const entities: RequestEntities = projectIdRef.current ? [{ entity: "collection_id", value: projectIdRef.current }] : [];

      dispatch(
        sendMessage({
          conversationId: currentConversationID,
          intent: `${questionText}`,
          entities: [...entities],
          files: files,
        })
      );

      if (files.length) resetFiles();
    },
    [selectedFocus, currentConversationID, dispatch, files, resetFiles]
  );

  const onSubmitGenerateAnswer = useCallback(
    (questionText: string, selectedFocus?: string, answerLength?: string) => {
      if (showModalUpdateBanner && projectId && isEmbeddingModelVersionOutdated && !isAIUpdatedModalOpen) {
        setSelectedQuestionAndFocus({ question: questionText || "", focus: selectedFocus || "", answerLength: answerLength || "" });
        onConversationClose();
        onAIUpdatedModalOpen();
        return;
      }
      if (!questionText || !projectIdRef.current || !selectedFocus || !currentConversationID) {
        console.error("Missing required parameters for onSubmitGenerateAnswer", {
          questionText,
          projectId: projectIdRef.current,
          selectedFocus,
          currentConversationID,
        });
        return;
      }

      if (
        !canBypassUsageLimits &&
        projectQuestionUsage &&
        projectQuestionUsage.allowed > 0 &&
        projectQuestionUsage.used >= projectQuestionUsage.allowed
      ) {
        onSubscriptionModalOpen();
        return;
      }

      const entities: RequestEntities = [
        { entity: "collection_id", value: projectIdRef.current },
        { entity: "question", value: questionText.trim() },
        { entity: "answer_focus", value: selectedFocus },
        { entity: "asked_by_user", value: "true" },
        { entity: "answer_format", value: "html" },
        { entity: "question_category", value: mode },
      ];
      if (answerLength) {
        entities.push({ entity: "answer_length", value: answerLength });
      }

      dispatch(
        sendMessage({
          conversationId: currentConversationID,
          intent: `/generate_answer`,
          entities: entities,
        })
      );

      showNewUX &&
        handleSubmitted(
          `I'll get started on answering ${questionText.trim()} now.This may take a few minutes.You can monitor the progress below.`,
          currentConversationID
        );
      showNewUX && setSubmittedQuestion(questionText.trim());

      setSelectedQuestionAndFocus({
        question: undefined,
        focus: undefined,
        answerLength: undefined,
        projectTicker: undefined,
        projectExchange: undefined,
        projectId: undefined,
      });

      !showNewUX && !isMobile && onConversationOpen(currentConversationID);
    },
    [
      showModalUpdateBanner,
      projectId,
      isEmbeddingModelVersionOutdated,
      isAIUpdatedModalOpen,
      currentConversationID,
      canBypassUsageLimits,
      projectQuestionUsage,
      mode,
      dispatch,
      showNewUX,
      handleSubmitted,
      setSubmittedQuestion,
      setSelectedQuestionAndFocus,
      isMobile,
      onConversationOpen,
      onConversationClose,
      onAIUpdatedModalOpen,
      onSubscriptionModalOpen,
    ]
  );

  const handleDueDiligenceProjectError = useCallback(
    (errorType: "usage_limit" | "max_projects") => {
      if (errorType === "usage_limit") {
        onSubscriptionModalOpen();
      } else {
        toast({
          render: ({ onClose }) =>
            createToastContent(`You can only have ${maxAllowedProjects} active due diligence projects at a time`, onClose, () => {
              onClose();
              onSubscriptionModalOpen();
            }),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    },
    [maxAllowedProjects, onSubscriptionModalOpen, toast]
  );

  const onSubmitDueDiligenceProject = useCallback(
    (
      ticker: string,
      exchange?: string,
      afterSubmit?: () => void,
      reRunProjectId?: string,
      reRunPortfolioId?: string,
      tickerType?: TickerType | null | undefined,
      modelUpgradedQuestionAndFocus?: SelectedQuestionAndFocus,
      isEmbeddingModelVersionOutdatedProp?: boolean
    ) => {
      if (
        projectId &&
        showModalUpdateBanner &&
        (isEmbeddingModelVersionOutdated || isEmbeddingModelVersionOutdatedProp) &&
        !isAIUpdatedModalOpen
      ) {
        setSelectedQuestionAndFocus({ question: questionText, focus: selectedFocus, answerLength: undefined });
        onConversationClose();
        onAIUpdatedModalOpen();
        return;
      }

      if (!canBypassUsageLimits && projectUsage && projectUsage.allowed > 0 && projectUsage.used >= projectUsage.allowed) {
        handleDueDiligenceProjectError("usage_limit");
        return;
      }

      if (!canBypassUsageLimits && hasExceededMaxProjects) {
        handleDueDiligenceProjectError("max_projects");
        return;
      }

      afterSubmit && afterSubmit();

      onSubmitDueDiligence({
        companyTickerOrName: ticker,
        companyStockExchange: exchange || "",
        isTicker: true,
        dispatch,
        onConversationOpen: showNewUX || isOnboardingModalOpen || isMobile ? undefined : onConversationOpen,
        onSubmitted: showNewUX
          ? () =>
              handleSubmitted(
                `I'll ${
                  reRunProjectId ? "rerun the" : "start a new"
                } project for ${ticker.trim()} now.This may take a few minutes.You can monitor the progress below.`,
                dueDiligenceConversationId,
                () => (projectIdRef.current ? () => navigate(-1) : undefined)
              )
          : () => (projectIdRef.current ? () => navigate(-1) : undefined),
        conversationId: dueDiligenceConversationId,
        showNewUX,
        additionalEntities: [],
        portfolioId: reRunPortfolioId ? reRunPortfolioId : portfolioId,
        reRunProjectId: reRunProjectId ? reRunProjectId : undefined,
        companyStockType: tickerType,
        modelUpgradedQuestionAndFocus: {
          question: modelUpgradedQuestionAndFocus?.question,
          focus: modelUpgradedQuestionAndFocus?.focus,
        },
      });

      setSelectedQuestionAndFocus({
        question: undefined,
        focus: undefined,
        answerLength: undefined,
        projectTicker: undefined,
        projectExchange: undefined,
        projectId: undefined,
      });
    },
    [
      projectId,
      showModalUpdateBanner,
      isEmbeddingModelVersionOutdated,
      isAIUpdatedModalOpen,
      canBypassUsageLimits,
      projectUsage,
      hasExceededMaxProjects,
      dispatch,
      showNewUX,
      isOnboardingModalOpen,
      isMobile,
      onConversationOpen,
      portfolioId,
      setSelectedQuestionAndFocus,
      questionText,
      selectedFocus,
      onConversationClose,
      onAIUpdatedModalOpen,
      handleDueDiligenceProjectError,
      handleSubmitted,
      dueDiligenceConversationId,
      navigate,
    ]
  );

  const currentSubmitFunction = useCallback(
    (questionText: string) => {
      if (
        conversation.conversationState === "action" ||
        conversation.conversationState === "in_progress" ||
        questionText.startsWith("/") ||
        files.length > 0
      ) {
        onSubmitDefaultRequest(questionText);
      } else if (projectIdRef.current) {
        onSubmitGenerateAnswer(questionText, selectedFocus);
      } else {
        const stockExchange = selectedFocus && !["analytical", "sentiment"].includes(selectedFocus) ? selectedFocus : undefined;
        if (stockExchange) {
          onSubmitDueDiligenceProject(questionText, stockExchange, afterSubmit);
        } else {
          console.error("Unable to determine submit function");
        }
      }
    },
    [
      conversation.conversationState,
      files.length,
      onSubmitDefaultRequest,
      onSubmitGenerateAnswer,
      selectedFocus,
      onSubmitDueDiligenceProject,
      afterSubmit,
    ]
  );

  return {
    currentSubmitFunction,
    onSubmitGenerateAnswer,
    onSubmitDefaultRequest,
    onSubmitDueDiligenceProject,
    currentConversationID,
    conversation,
    isConversationOpen,
    questionText,
  };
};
