// dueDiligence.ts
import { v4 as uuid } from "uuid";
import { sendMessage } from "state/websocket/operations";
import type { Dispatch } from "redux";
import type { TickerType } from "api/tickers/models/TickerType";
import type { SelectedQuestionAndFocus } from "screens/thread/ConversationContext";

interface Entity {
  entity: string;
  value: string;
}

type RequestEntities = Entity[];

interface SubmitDueDiligenceProps {
  companyTickerOrName: string;
  companyStockExchange: string;
  isTicker: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
  onConversationOpen?: (conversationId: string) => void;
  conversationId?: string;
  showNewUX?: boolean;
  onSubmitted?: () => void;
  additionalEntities?: RequestEntities;
  portfolioId?: string;
  reRunProjectId?: string;
  companyStockType?: TickerType | null | undefined;
  modelUpgradedQuestionAndFocus?: SelectedQuestionAndFocus;
  scheduledStartTimestamp?: number;
}

const useSubmitDueDiligence = ({
  companyTickerOrName,
  companyStockExchange,
  isTicker,
  dispatch,
  onConversationOpen,
  conversationId,
  showNewUX,
  onSubmitted,
  additionalEntities = [],
  portfolioId,
  reRunProjectId,
  companyStockType,
  modelUpgradedQuestionAndFocus,
  scheduledStartTimestamp,
}: SubmitDueDiligenceProps) => {
  const entities: RequestEntities = [];
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  entities.push({ entity: "collection_type", value: "due_diligence" });
  if (isTicker) {
    entities.push({ entity: "company_ticker", value: companyTickerOrName.trim() });
  } else {
    entities.push({ entity: "company_name", value: companyTickerOrName.trim() });
  }
  if (companyStockExchange && companyStockExchange.length > 0) {
    entities.push({ entity: "company_stock_exchange", value: companyStockExchange });
  }
  if (portfolioId) {
    entities.push({ entity: "portfolio_id", value: portfolioId });
  }
  if (timezone) {
    entities.push({ entity: "client_time_zone", value: timezone });
  }
  if (reRunProjectId) {
    entities.push({ entity: "as_rerun_of_project_id", value: reRunProjectId });
  }
  if (companyStockType) {
    entities.push({ entity: "security_type", value: companyStockType });
  }
  if (modelUpgradedQuestionAndFocus && modelUpgradedQuestionAndFocus.question && modelUpgradedQuestionAndFocus.focus) {
    entities.push({ entity: "rerun_caused_by_model_upgrade_question", value: modelUpgradedQuestionAndFocus.question });
    entities.push({ entity: "rerun_caused_by_model_upgrade_answer_focus", value: modelUpgradedQuestionAndFocus.focus });
  }
  if (!modelUpgradedQuestionAndFocus?.focus) {
    entities.push({ entity: "answer_focus", value: "analytical" });
  }
  const newConversationId = conversationId ?? uuid();

  dispatch(
    sendMessage({
      conversationId: newConversationId,
      intent: `/create_due_diligence_project`,
      entities: [...entities, ...additionalEntities],
      ...(scheduledStartTimestamp && { scheduledStartTimestamp }),
    })
  );

  if (onSubmitted) {
    onSubmitted();
    !showNewUX &&
      setTimeout(() => {
        onConversationOpen && onConversationOpen(newConversationId);
      }, 500);
  } else {
    onConversationOpen && onConversationOpen(newConversationId);
  }
};

export default useSubmitDueDiligence;
