import { createAsyncThunk } from "@reduxjs/toolkit";
import { Message as ApiMessage } from "api/conversation/models/Message";
import { getWebsocketClient } from "api/websocket/client";
import { MessageType } from "api/websocket/MessageType";
import { formatISO, parseISO } from "date-fns";
import { actions as checkpointsActions } from "state/checkpoints/reducer";
import type { CharliUI } from "types/charliui";
import type { Message } from "types/conversation";
import type { UppyFile } from "types/files";
import type { TaskUpdate } from "types/workflows/workflow";
import { v4 as uuid } from "uuid";
import store from "../store";
import type { DeleteContentResponse } from "./api/DeleteContentResponse";
import type { RefreshContentResponse } from "./api/RefreshContentResponse";
import isEmpty from "lodash/isEmpty";
import { downloadCollections, removeCollection } from "../collection/operations";
import { actions as conversationActions } from "../conversation/reducer";
import { actions as usageActions } from "../usage/reducer";
import type { RootState } from "../rootReducer";
import type { RefreshWorkflowResponse } from "./api/RefreshWorkflowResponse";
import { actions as websocketActions } from "./reducer";
import { requestToDownloadWorkflows } from "state/workflow/utils";
import type { UpdateWorkflowProgressResponse } from "./api/UpdateWorkflowProgressResponse";
import reduce from "lodash/reduce";
// TODO: cleanup this after the new progress steps are implemented
import type { UpdateChildWorkflowStatusResponse } from "./api/UpdateChildWorkflowStatusResponse";
import { downloadProgressSteps } from "state/progressSteps/operations";
import { requestToDownloadContents } from "state/content/utils";
import type { FeatureUsage } from "api/usage/models/FeatureUsage";
import type { UpdateChildWorkflowProgressResponse } from "./api/UpdateChildWorkflowProgressResponse";
import type { WorkflowMilestoneUpdate } from "./api/WorkflowMilestoneUpdate";
import { actions as milestonesActions } from "state/milestones/reducer";

const memoizedWorkflowIntentFilters = new Map<string, string[]>();
const collectionsFetchTimeouts = {};
const contentsFetchTimeouts = {};

const handleMessage = (jsonString: string, messageType: MessageType, thunkAPI) => {
  const rootState = thunkAPI.getState() as RootState;

  if (localStorage.getItem("log_incoming_messages")) {
    console.log(`Incoming message`, jsonString);
  }

  switch (messageType) {
    case MessageType.conversations: {
      try {
        const messageValidationResult = ApiMessage.validate(JSON.parse(jsonString));

        if (messageValidationResult.success) {
          const apiMessage = messageValidationResult.value;

          const message: Message = {
            id: apiMessage.id,
            acknowledgmentStatus: "acknowledged",
            senderId: apiMessage.senderId,
            createdDate: apiMessage.createdDate,
            createdTime: parseISO(apiMessage.createdDate).getTime(),
            recipientId: apiMessage.recipientId,
            conversationId: apiMessage.conversationId,
            parentMessageId: undefined,
            intent: apiMessage.intent,
            content: apiMessage.content ?? undefined,
            state: apiMessage.state ?? undefined,
            // it'll take a lot to fully map all the possible objects in this to runtypes
            // so for now, we'll keep casting like we used to
            data: apiMessage.data as CharliUI[],
            viewId: undefined,
            entities: undefined,
          };

          const { data } = message;

          const workflowData = data?.flatMap((uiData) => (uiData.type === "workflow_created" ? [uiData] : []));

          if (workflowData && workflowData.length > 0) {
            const workflowCreatedId = workflowData[0].body.id;

            requestToDownloadWorkflows([workflowCreatedId], rootState.workflow.isLoadingWorkflowMap, store.dispatch);
          }

          message.createdTime = parseISO(message.createdDate).getTime();

          thunkAPI.dispatch(conversationActions.addMessage(message));
        } else {
          console.error("Invalid conversation message received from websocket", messageValidationResult.details);
        }
      } catch (err) {
        console.error(err);
      }

      return;
    }

    case MessageType.workflowProgress: {
      const workflowFullProgress = JSON.parse(jsonString) as UpdateWorkflowProgressResponse;

      store.dispatch(
        checkpointsActions.setCheckpointsWorkflows([{ id: workflowFullProgress.workflowId, progress: workflowFullProgress.progress }])
      );

      return;
    }

    case MessageType.childWorkflowsProgress: {
      const childWorkflowProgress = JSON.parse(jsonString) as UpdateChildWorkflowProgressResponse;

      store.dispatch(
        checkpointsActions.setCheckpointsChildWorkflows({
          workflowId: childWorkflowProgress.workflowId,
          progressByChildWorkflow: {
            [childWorkflowProgress.childWorkflowId]: {
              completedStepCount: childWorkflowProgress.progress.completedStepCount,
              estimatedTotalStepCount: childWorkflowProgress.progress.estimatedTotalStepCount,
              id: childWorkflowProgress.childWorkflowId,
              intent: childWorkflowProgress.intent,
            },
          },
        })
      );

      return;
    }

    case MessageType.workflowTaskStart: {
      const taskResponse = JSON.parse(jsonString) as TaskUpdate;

      if (taskResponse.status && taskResponse.taskId) {
        thunkAPI.dispatch(
          checkpointsActions.setChildWorkflowTask({
            taskId: taskResponse.taskId,
            workflowId: taskResponse.workflowId,
            childWorkflowId: taskResponse.childWorkflowId,
            name: taskResponse.name,
            status: taskResponse.status,
          })
        );
      }

      thunkAPI.dispatch(
        checkpointsActions.setWorkflowRunningTask({
          workflowId: taskResponse.workflowId,
          name: taskResponse.name,
        })
      );

      return;
    }

    case MessageType.refreshContent: {
      const refreshContent = JSON.parse(jsonString) as RefreshContentResponse;
      const refreshTimeout = 1000;

      if (!isEmpty(refreshContent.metadataIds)) {
        refreshContent.metadataIds.forEach((metadataId) => {
          if (contentsFetchTimeouts[metadataId]) {
            clearTimeout(contentsFetchTimeouts[metadataId]);
          }

          contentsFetchTimeouts[metadataId] = setTimeout(() => {
            requestToDownloadContents({ metadataIds: [metadataId] }, thunkAPI.dispatch);

            delete contentsFetchTimeouts[metadataId];
          }, refreshTimeout);
        });
      }
      if (!isEmpty(refreshContent.collectionIds)) {
        refreshContent.collectionIds.forEach((collectionId) => {
          if (collectionsFetchTimeouts[collectionId]) {
            clearTimeout(collectionsFetchTimeouts[collectionId]);
          }

          collectionsFetchTimeouts[collectionId] = setTimeout(() => {
            store.dispatch(downloadCollections({ ids: [collectionId] }) as never);

            delete collectionsFetchTimeouts[collectionId];
          }, refreshTimeout);
        });
      }
      return;
    }

    case MessageType.deleteContent: {
      const deleteContent = JSON.parse(jsonString) as DeleteContentResponse;
      deleteContent.collectionIds.forEach((collectionId) => store.dispatch(removeCollection({ id: collectionId })));
      return;
    }

    case MessageType.refreshWorkflow: {
      const refreshWorkflow = JSON.parse(jsonString) as RefreshWorkflowResponse;

      requestToDownloadWorkflows([refreshWorkflow.workflowId], rootState.workflow.isLoadingWorkflowMap, thunkAPI.dispatch);

      return;
    }

    case MessageType.childWorkflowStatusUpdate: {
      // TODO: cleanup this after the new progress steps are implemented
      const refreshStepper = JSON.parse(jsonString) as UpdateChildWorkflowStatusResponse;

      // Find the collection linked to the workflow
      // Then find the config with collectionType equal to the collection and grab the workflowIntentFilters
      const workflowIntentFilters = (() => {
        if (memoizedWorkflowIntentFilters.has(refreshStepper.workflowId)) {
          return memoizedWorkflowIntentFilters.get(refreshStepper.workflowId) ?? [];
        }

        const maybeCollectionId = rootState.collection.order.find((id) =>
          rootState.collection.collections[id].workflowIds?.includes(refreshStepper.workflowId)
        );

        if (!maybeCollectionId) {
          return [];
        }

        const collectionLinkedToWorkflow = rootState.collection.collections[maybeCollectionId];
        const maybeConfigId = rootState.configMap.order.find(
          (id) => rootState.configMap.defaultConfigById[id].config.collectionType === collectionLinkedToWorkflow.collectionType
        );

        if (!maybeConfigId) {
          return [];
        }

        const workflowIntentFilters = rootState.configMap.defaultConfigById[maybeConfigId].config.workflowIntentFilters ?? [];

        if (workflowIntentFilters.length > 0) {
          memoizedWorkflowIntentFilters.set(refreshStepper.workflowId, workflowIntentFilters);
        }

        return workflowIntentFilters;
      })();

      if (workflowIntentFilters.includes(refreshStepper.intent)) {
        store.dispatch(downloadProgressSteps({ workflowIds: [refreshStepper.workflowId], intentFilters: workflowIntentFilters }));
      }

      store.dispatch(
        checkpointsActions.setStatusByChildWorkflow({ childWorkflowId: refreshStepper.childWorkflowId, status: refreshStepper.status })
      );

      return;
    }

    case MessageType.usageUpdate: {
      const usageUpdate = JSON.parse(jsonString) as Record<string, FeatureUsage>;

      store.dispatch(usageActions.setUsage(usageUpdate));

      return;
    }

    case MessageType.workflowMilestone: {
      const workflowMilestone = JSON.parse(jsonString) as WorkflowMilestoneUpdate;

      if (workflowMilestone.skipped) {
        thunkAPI.dispatch(milestonesActions.removeMilestone(workflowMilestone));
      } else {
        thunkAPI.dispatch(milestonesActions.setMilestone(workflowMilestone));
      }

      return;
    }

    default: {
      return;
    }
  }
};

export const connect = createAsyncThunk("websocket/connect", async (clientId: string, thunkAPI) => {
  await getWebsocketClient().connect(
    clientId,
    () => {
      thunkAPI.dispatch(websocketActions.connect());
    },
    () => {
      const state = thunkAPI.getState() as RootState;

      if (state.session.isLoggedIn) {
        thunkAPI.dispatch(websocketActions.disconnect({ userInitiated: false }));
      }
    },
    (jsonString: string, messageType?: MessageType) => {
      if (jsonString === "" || !messageType) {
        return;
      }

      handleMessage(jsonString, messageType, thunkAPI);
    }
  );
});

export const disconnect = createAsyncThunk("websocket/disconnect", async (body, thunkAPI) => {
  thunkAPI.dispatch(websocketActions.disconnect({ userInitiated: true }));
  getWebsocketClient().close();
});

export const sendMessage = createAsyncThunk(
  "websocket/send",
  async (
    args: {
      conversationId: string;
      disableDebugging?: boolean;
      disableTesting?: boolean;
      viewId?: string;
      parentMessageId?: string;
      message?: string;
      intent?: string;
      entities?: { entity: string; value: unknown }[];
      datum?: CharliUI[];
      files?: UppyFile[];
      scheduledStartTimestamp?: number;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const sender = state.session.user!.email;
    const files = args.files ?? [];
    const shouldInjectDebugEntity =
      (!args.disableDebugging && state.userPreference.userPreferences.ui_inject_debug_entity === true) || false;
    const shouldInjectTestEntity =
      (args.intent !== "/find" && !args.disableTesting && state.userPreference.userPreferences.ui_inject_test_entity === true) || false;

    let data;
    const viewId = args.viewId;
    const parentMessageId = args.parentMessageId;

    if (files.length) {
      data = files.map((file) => {
        return {
          type: "file",
          body: {
            file_id: file.meta.key,
            file_name: file.meta.name,
            file_mime_type: file.type || "application/octet-stream",
          },
        };
      });
    } else if (args.datum) {
      data = args.datum;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
      data = data ? data.concat({ type: "timezone", body: { timezone } }) : [{ type: "timezone", body: { timezone } }];
    }

    const reorderedEntities = reduce(
      args.entities,
      (acc: { entity: string; value: unknown }[], entity) => (entity.value ? acc.concat(entity) : [entity].concat(acc)),
      []
    );

    const commandSyntaxEntities =
      reorderedEntities?.map((entity) => {
        return `>${entity.entity} ${entity.value}`;
      }) ?? [];

    // if there is no intent entities or files then dont send the message
    if (!args.intent && !commandSyntaxEntities.length && !files.length) {
      return;
    }

    const debugEntity = shouldInjectDebugEntity ? [">debug true"] : [];
    const testEntity = shouldInjectTestEntity ? [">test_mode true"] : [];

    const commandSyntaxMessage = [args.intent, ...commandSyntaxEntities, ...debugEntity, ...testEntity].join(" ");

    const now = new Date();

    const message: Message = {
      id: uuid(),
      conversationId: args.conversationId,
      viewId: viewId,
      parentMessageId: parentMessageId,
      content: commandSyntaxMessage.trim(),
      intent: args.intent,
      entities: args.entities,
      senderId: sender,
      createdDate: formatISO(now),
      createdTime: now.getTime(),
      data: data,
      acknowledgmentStatus: "not_acknowledged",
    };

    thunkAPI.dispatch(conversationActions.addMessage(message));

    const commandSyntaxMessageForBackend = {
      id: message.id,
      conversationId: message.conversationId,
      content: commandSyntaxMessage.trim(),
      senderId: message.senderId,
      createdDate: message.createdDate,
      data: message.data,
      scheduledStartTimestamp: args.scheduledStartTimestamp,
    };

    await getWebsocketClient().send(commandSyntaxMessageForBackend);
  }
);
