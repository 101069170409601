import type { FunctionComponent } from "react";
import React from "react";
import { Box, IconButton, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface Props {
  message: string;
  onClick?: () => void;
  onClose: () => void;
}

export const ToastMessageContent: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  message,
  onClick,
  onClose,
}) => {
  const primaryTextColor = useColorModeValue("gray.700", "gray.300");
  const bgColor = useColorModeValue("charli.lightBlue", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.800");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Box
      maxWidth={isMobile ? "100vw" : "20rem"}
      id="conversation-toast"
      fontSize={"sm"}
      color={primaryTextColor}
      borderColor={borderColor}
      borderWidth={"1px"}
      cursor="pointer"
      boxShadow={"md"}
      p="1rem"
      mt="3rem"
      bg={bgColor}
      borderRadius={isMobile ? "none" : "lg"}
      mr={isMobile ? "unset" : "1rem"}
      position="relative"
      onClick={() => {
        if (onClick) onClick();
        onClose();
      }}>
      {message}
      <Box
        position="absolute"
        right="-0.5rem"
        top="-0.5rem"
        bg={bgColor}
        borderRadius={"full"}
        borderColor={borderColor}
        borderWidth={"1px"}>
        <IconButton
          p=".5rem"
          aria-label="Close"
          borderRadius={"full"}
          icon={<CloseIcon color={primaryTextColor} boxSize="10px" borderRadius={"full"} />}
          size="xs"
          onClick={(event) => {
            onClose();
            event.stopPropagation();
          }}
        />
      </Box>
    </Box>
  );
};
