import type { StackProps, BoxProps } from "@chakra-ui/react";
import { Stack, Text, Center, Box, useBreakpointValue, Image, ListItem, UnorderedList } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";
import { useCopyValue } from "hooks/useCopies";
import SlideImage1 from "screens/common/static/images/SlideImage1.png";
import { track } from "api/analytics";
import { ONBOARDING_TICKER_SENT } from "api/analytics/events";
import { useUserProfile } from "hooks";
import { useDispatch } from "react-redux";
import { updateTypedUserPreference } from "state/userPreference/operations";

interface OnboardingNewProjectStepProps {
  equityPortfolioId?: string;
}

const stackBaseStyles: StackProps = {
  justifyContent: "space-between",
  spacing: "0",
};

const headerStackStyles: StackProps = {
  height: "100%",
  spacing: "1rem",
  bgColor: "primary.default",
  color: "white",
};

const titleStyles = {
  fontWeight: "semibold",
  fontSize: "2xl",
} as const;

const subtitleStyles = {
  fontSize: "md",
  fontWeight: "semibold",
  width: "100%",
  textAlign: "center",
  px: "3rem",
} as const;

const imageStyles = {
  position: "absolute",
  height: "10rem",
} as const;

const inputContainerStyles: BoxProps = {
  backgroundColor: "gray.50",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const OnboardingNewProjectStep: React.FC<OnboardingNewProjectStepProps> = React.memo(({ equityPortfolioId }) => {
  const { isOnboardingModalOpen, onOnboardingModalClose } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { fullName = "", email = "" } = useUserProfile();
  const dispatch = useDispatch();

  const copyOnboardingTitle = useCopyValue("copy_onboarding_modal_step1_title");
  const copyOnboardingSubTitle = useCopyValue("copy_onboarding_modal_step1_subtitle");
  const copyOnboardingList = useCopyValue("copy_onboarding_modal_step1_list");

  const handleOnSubmitDueDiligence = useCallback(() => {
    if (isOnboardingModalOpen) {
      track(ONBOARDING_TICKER_SENT, { userName: fullName, email });
      dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
      isOnboardingModalOpen && onOnboardingModalClose();
      onOnboardingModalClose();
    }
  }, [dispatch, email, fullName, isOnboardingModalOpen, onOnboardingModalClose]);

  const mobileAdjustedStyles = useMemo(
    () => ({
      mainStack: {
        ...stackBaseStyles,
        height: isMobile ? "30rem" : "39rem",
      } as StackProps,
      header: {
        ...headerStackStyles,
        px: isMobile ? "1rem" : "2rem",
        pt: "2rem",
        pb: "4rem",
      } as StackProps,
      image: {
        ...imageStyles,
        mt: isMobile ? "-2rem" : "4rem",
      },
      inputWrapper: {
        width: "80%",
        pt: isMobile ? "0" : "6rem",
        pointerEvents: equityPortfolioId ? "initial" : "none",
      } as BoxProps,
    }),
    [isMobile, equityPortfolioId]
  );

  const renderList = useMemo(() => {
    if (isMobile) return null;

    return (
      <Box>
        <UnorderedList spacing={3} fontSize="md" px="5rem">
          {copyOnboardingList.map((item: string, index: number) => (
            <ListItem key={`onboarding-list-${index}`}>{item}</ListItem>
          ))}
        </UnorderedList>
      </Box>
    );
  }, [isMobile, copyOnboardingList]);

  return (
    <Stack {...mobileAdjustedStyles.mainStack}>
      <Stack {...stackBaseStyles} height="100%">
        <Stack {...mobileAdjustedStyles.header}>
          <Center>
            <Text {...titleStyles}>{copyOnboardingTitle}</Text>
          </Center>
          <Text {...subtitleStyles}>{copyOnboardingSubTitle}</Text>
          {renderList}
        </Stack>
        <Center>
          <Image {...mobileAdjustedStyles.image} src={SlideImage1} />
        </Center>
        <Box {...inputContainerStyles}>
          <Box {...mobileAdjustedStyles.inputWrapper}>
            <ConversationDialogInput
              inputId="view-input"
              afterSubmit={handleOnSubmitDueDiligence}
              initialText={equityPortfolioId ? "Enter a ticker here to get started" : "Creating Portfolios..."}
              renderRole="embedded"
              portfolioId={equityPortfolioId}
            />
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
});

OnboardingNewProjectStep.displayName = "OnboardingNewProjectStep";
