import React from "react";
import type { FunctionComponent } from "react";
import { useColorModeValue, MenuItem } from "@chakra-ui/react";

interface Props {
  menuLabel: string;
  className?: string;
  isDisabled?: boolean;
  onClick?: (event: { stopPropagation: () => void }) => void;
}

export const OptionsMenuItem: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  menuLabel,
  className,
  onClick,
  isDisabled,
}) => {
  const fontColor = useColorModeValue("gray.500", "gray.400");

  return (
    <MenuItem
      _hover={{ color: "primary.default" }}
      className={className}
      isDisabled={isDisabled}
      fontSize={"sm"}
      color={fontColor}
      onClick={onClick}>
      {menuLabel}
    </MenuItem>
  );
};
