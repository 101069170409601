import React from "react";
import { Avatar, Center, MenuList, useColorModeValue, Menu, MenuButton, MenuItem, Icon, Stack, Text, Tooltip } from "@chakra-ui/react";
import { useEntitlementKey, useUserProfile } from "hooks";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { HiOutlineCog, HiOutlineLockClosed, HiOutlinePuzzle } from "react-icons/hi";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

export const ProfileSettingsMenu = () => {
  const { fullName, avatarUrl } = useUserProfile();
  const buttonColor = useColorModeValue("primaryButton.color", "gray.400");
  const navigate = useNavigate();
  const { onSettingsOpen } = useAddToCharliContext();
  const buttonTextColor = useColorModeValue("gray.600", "gray.400");
  const hasIntegrationsAccess = useEntitlementKey("ui_enable_integrations_menu");

  return (
    <Menu isLazy>
      <MenuButton className="ch-profile-menu">
        <Center cursor="pointer">
          <Avatar name={fullName} src={avatarUrl} boxSize="1.6rem" fontSize={"xs"} />
          <ChevronDownIcon boxSize="1.3rem" color={buttonColor} />
        </Center>
      </MenuButton>
      <MenuList minWidth={"10.5rem"} zIndex={3}>
        <MenuItem color={buttonTextColor} _hover={{ color: "primary.default" }} onClick={onSettingsOpen} className="ch-profile-settings">
          <Stack
            cursor="pointer"
            direction="row"
            spacing="1rem"
            _hover={{
              color: "primary.default",
            }}>
            <Center>
              <Icon as={HiOutlineCog} boxSize="1.1rem" />
            </Center>
            <Text fontSize="sm">Account Settings</Text>
          </Stack>
        </MenuItem>
        <Tooltip label={hasIntegrationsAccess ? "" : "Upgrade to Enterprise to access integrations."} width="10rem">
          <MenuItem
            color={buttonColor}
            _hover={{ color: "primary.default" }}
            isDisabled={!hasIntegrationsAccess}
            onClick={() => {
              navigate("/integrations");
            }}
            className="ch-profile-integrations">
            <Stack direction="row" spacing="1rem">
              <Center>
                <Icon as={HiOutlinePuzzle} boxSize="1.1rem" />
              </Center>
              <Text fontSize="sm">Integrations</Text>
            </Stack>
          </MenuItem>
        </Tooltip>
        <MenuItem
          onClick={() => {
            navigate("/logout");
          }}
          color={buttonTextColor}
          _hover={{
            color: "primary.default",
          }}
          className="ch-profile-logout">
          <Stack cursor="pointer" direction="row" spacing="1rem">
            <Center>
              <Icon as={HiOutlineLockClosed} boxSize="1.1rem" />
            </Center>
            <Text fontSize="sm">Log Out</Text>
          </Stack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
