import type { BoxProps, IconProps, TableCellProps, TextProps } from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/react";
import { useMemo } from "react";

type ButtonType = "primary" | "secondary" | "cta" | "subscribe";

export const buttonStyles = {
  primary: {
    bgColor: "white",
    color: "primary.default",
    borderColor: "primary.default",
    hoverBgColor: "gray.50",
    hoverBorderColor: "primary.default",
  },
  secondary: {
    bgColor: "white",
    color: "gray.500",
    hoverBorderColor: "gray.400",
    hoverBgColor: "gray.50",
    borderColor: "gray.300",
  },
  cta: {
    bgColor: "primary.default",
    color: "white",
    hoverBorderColor: "#318baf",
    hoverBgColor: "#318baf",
    borderColor: "primary.default",
  },
  subscribe: {
    bgColor: "#fdb516",
    color: "gray.700",
    hoverBorderColor: "#ffcc5a",
    hoverBgColor: "#ffcc5a",
    borderColor: "#fdb516",
  },
};

export const useButtonProps = (size?: string, type: ButtonType = "primary", showBoxShadow?: false) => {
  const { bgColor, color, hoverBorderColor, hoverBgColor, borderColor } = buttonStyles[type];

  return useMemo(
    () => ({
      size: size ?? "sm",
      backgroundColor: bgColor,
      color,
      borderRadius: "md",
      borderColor,
      variant: "outline",
      _active: {},
      _hover: { backgroundColor: hoverBgColor, borderColor: hoverBorderColor, boxShadow: showBoxShadow ? "md" : "none" },
    }),
    [size, bgColor, color, borderColor, hoverBorderColor, hoverBgColor, showBoxShadow]
  );
};

export const useLinkActionProps = (size?: string) => {
  const commonLinkActionProps = useMemo(
    () => ({
      justifyContent: "left",
      size: size ?? "sm",
      fontWeight: "400",
      backgroundColor: "primary.default",
      color: "white",
      borderRadius: "full",
      borderColor: "primary.default",
      boxShadow: "xl",
      _active: {},
      _hover: {},
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size]
  );

  return commonLinkActionProps;
};

export const useTabProps = () => {
  const tabButtonColor = useColorModeValue("gray.500", "gray.600");
  const linkColor = useColorModeValue("gray.600", "gray.300");
  const tabHoverColor = useColorModeValue("secondaryButton.hover", "secondaryButton.hoverDarkMode");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const commonTabProps = useMemo(() => {
    return {
      fontSize: ["sm", "md", "md"],
      fontWeight: "normal",
      color: tabButtonColor,
      justifyContent: "left",
      border: "none",
      _selected: { borderColor: linkColor, borderBottom: "1px solid", color: tabHoverColor, fontWeight: "bold" },
      _hover: {
        color: tabHoverColor,
        borderColor: tabHoverColor,
      },
    };
  }, [tabButtonColor, linkColor, tabHoverColor]);

  const defaultTabProps = useMemo(() => {
    return {
      fontSize: isMobile ? "sm" : "md",
      width: isMobile ? "unset" : "12rem",
      justifyContent: isMobile ? "center" : "flex-start",
      borderColor: "gray.200",
      mr: isMobile ? "0" : "1rem",
      _selected: { fontWeight: "bold", color: "primary.default", borderColor: "primary.default" },
      _active: {},
      _hover: {
        color: tabHoverColor,
      },
    };
  }, [isMobile, tabHoverColor]);

  return { commonTabProps, defaultTabProps };
};

export const useIconProps = () => {
  const buttonColor = useColorModeValue("gray.500", "gray.400");
  const buttonBgColor = useColorModeValue("white", "transparent");
  const hoverColor = useColorModeValue("primary.default", "gray.300");
  const hoverBgColor = useColorModeValue("white", "transparent");

  const commonIconProps = useMemo(() => {
    return {
      fontWeight: "300",
      "aria-label": "icon",
      backgroundColor: buttonBgColor,
      color: buttonColor,
      cursor: "pointer",
      boxSize: "1.4rem",
      _hover: {
        color: hoverColor,
        borderColor: buttonColor,
        backgroundColor: hoverBgColor,
      },
    };
  }, [buttonColor, buttonBgColor, hoverColor, hoverBgColor]);

  return commonIconProps as IconProps;
};

export const useTileProps = () => {
  const bgColor = useColorModeValue("white", "#181d26");
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const borderColorHover = useColorModeValue("gray.400", "gray.600");

  const commonTileProps = useMemo(() => {
    return {
      position: "relative",
      cursor: "pointer",
      backgroundColor: bgColor,
      borderColor: tileBorderColor,
      borderWidth: "1px",
      borderRadius: ".375rem",
      px: "1rem",
      py: ".5rem",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "normal",
      _hover: {
        borderColor: borderColorHover,
      },
    };
  }, [bgColor, borderColorHover, tileBorderColor]);

  return commonTileProps as BoxProps;
};

export const useMetricProps = () => {
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");
  const commonMetricProps: TextProps = {
    isTruncated: true,
    fontWeight: "light",
    color: titleColor,
    textAlign: "center",
  };

  return commonMetricProps;
};

export const useTableProps = () => {
  const color = useColorModeValue("charli.lightGray", "gray.500");
  const commonTableTdProps: TableCellProps = {
    border: "none",
    color: color,
  };

  return commonTableTdProps;
};

export const useSkeletonProps = () => {
  const startColor = useColorModeValue("gray.200", "gray.900");
  const endColor = useColorModeValue("gray.100", "gray.800");

  const commonSkeletonProps = useMemo(() => {
    return {
      startColor: startColor,
      endColor: endColor,
      borderRadius: "md",
    };
  }, [startColor, endColor]);

  return commonSkeletonProps;
};
