import React, { useMemo, useState } from "react";
import { useColorModeValue, Text, useBreakpointValue, Td, Tr, Center, useDisclosure, Stack, Tag, Box } from "@chakra-ui/react";
import { useProjectParams } from "hooks";
import { getDateFromContent, getExtension, getParentCellType } from "screens/content/common/utils";
import { formatDate } from "screens/common/modal/formatters";
import type { ContentDetails } from "types/content/ContentDetails";
import { useConversationContext } from "screens/thread/ConversationContext";
import { FileTypeBadge } from "screens/common/components";
import { ProjectReportPanelDeleteModal } from "./ProjectReportPanelDeleteModal";
import { hasProjectOutput } from "screens/collection/components/utils";
import { AiOutlineDelete } from "react-icons/ai";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { ProjectReportPanelDownloadButton } from "./ProjectReportPanelDownloadButton";

interface Props {
  contentData: ContentDetails[];
}

export const ProjectReportPanelTableContent = ({ contentData }: Props) => {
  const { contentId, projectId } = useProjectParams();
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const secondaryTextColor = useColorModeValue("primary.darkGray", "gray.400");
  const { conversationId } = useConversationContext();
  const currentConversationId = contentId ? contentId : conversationId;
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { isOpen: isDeleteReport, onOpen: onOpenDeleteReport, onClose: onCloseDeleteReport } = useDisclosure();
  const [isSelectedContent, setIsSelectedContent] = useState<ContentDetails | undefined>(undefined);

  const contentDataFiltered = useMemo(() => {
    const filtered = contentData?.filter((content) => {
      return hasProjectOutput(content);
    });
    const sorted = filtered?.sort((a: ContentDetails, b: ContentDetails) => {
      return new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime();
    });
    return sorted;
  }, [contentData]);

  return (
    <>
      {contentDataFiltered &&
        contentDataFiltered.map((contentData, index) => (
          <Tr key={contentData.id}>
            <Td p={isMobile ? "0" : ".5rem"}>
              <Stack direction="row" spacing="1rem" justifyContent={"space-between"} width="100%">
                <Text wordBreak="break-word" fontSize="sm" className="file-name" textColor={textColor}>
                  {contentData.name && <>{contentData.name.split(" - ").slice(0, -1).join(" : ")?.trim() || contentData.name}</>}
                </Text>
                {!isMobile && index === 0 && (
                  <Center>
                    <Tag size={"sm"} variant="solid" colorScheme="green">
                      LATEST
                    </Tag>
                  </Center>
                )}
              </Stack>
            </Td>
            {!isMobile && (
              <Td p={isMobile ? "0" : ".5rem"}>
                <Center>
                  <FileTypeBadge
                    width="1.2rem"
                    height="1.6rem"
                    hideExtensionLabel
                    fileTypeName={contentData.collectionType}
                    extension={getExtension(contentData)}
                  />
                </Center>
              </Td>
            )}
            {!isMobile && (
              <Td p={isMobile ? "0" : ".5rem"} width="10rem">
                <Text wordBreak="break-word" fontWeight="light" fontSize="sm" textColor={secondaryTextColor}>
                  {formatDate(getDateFromContent(contentData), `${isMobile ? "d-MM-yy" : "MMM d, yyyy h:mm a"}`)}
                </Text>
              </Td>
            )}
            <Td p={isMobile ? "0" : ".5rem"}>
              <Center>
                <Stack direction="row" spacing="1rem">
                  <Box
                    className="ch-delete-report"
                    onClick={() => {
                      setIsSelectedContent(contentData);
                      onOpenDeleteReport();
                    }}>
                    <SmallActionButton
                      iconTypeName={AiOutlineDelete}
                      onClick={() => {
                        setIsSelectedContent(contentData);
                        onOpenDeleteReport();
                      }}
                      tooltip={"Remove Report"}
                    />
                  </Box>
                  <ProjectReportPanelDownloadButton
                    showAsIconButton
                    downloadUrl={contentData.urls?.downloadUrl || ""}
                    integrationUrn={contentData.integrationUrn || ""}
                    resourceName={contentData.name || ""}
                    isDisabled={!contentData.urls?.downloadUrl}
                  />
                </Stack>
              </Center>
            </Td>
          </Tr>
        ))}
      {isSelectedContent && projectId && (
        <ProjectReportPanelDeleteModal
          onClose={onCloseDeleteReport}
          isOpen={isDeleteReport}
          collectionId={projectId}
          conversationId={currentConversationId}
          mediaId={isSelectedContent.mediaId}
          parentCellTypeEntityName={getParentCellType(isSelectedContent) + "_id"}
          reason={"Deleted by user"}
          reportName={isSelectedContent.name}
        />
      )}
    </>
  );
};
